<script>
  import { mapActions } from "vuex";
  export default {
    components: {
      UsersListVisor: () => import("../widgets/Modals/UsersListVisor.vue"),
    },
    props: {
      close: {
        type: Function,
        require: true,
      },
      competitionId: {
        type: String,
        require: true,
      },
    },
    data() {
      return {
        isLoading: true,
        page: 0,
        hasMore: false,
        userViewers: [],
      };
    },
    methods: {
      ...mapActions(["getItemViewers"]),
      setModeScroll(mode = "initial") {
        if (!document) {
          return;
        }

        document.body.style.overflowY = mode;
      },
      async getMoreViewers() {
        if (!this.hasMore || this.isLoading) {
          return;
        }
        this.page++;
        this.userViewers.push(...(await this.getViewers()));
      },
      async showUsersView() {
        this.userViewers = await this.getViewers();
      },
      async getViewers() {
        if (!this.competitionId) {
          return;
        }
        this.isLoading = true;

        const { viewers, hasMore } = await this.getItemViewers({ item: this.competitionId, page: this.page });

        this.hasMore = hasMore;
        this.isLoading = false;

        return viewers.map(({ user, dates }) => ({ ...user, views: dates.length })) || [];
      },
    },
    beforeMount() {
      this.setModeScroll("hidden");
      this.showUsersView();
    },
    beforeDestroy() {
      this.setModeScroll();
    },
  };
</script>

<template>
  <UsersListVisor :users="userViewers" :isLoading="isLoading" :hasMore="hasMore" :detail="'Usuarios que han visto el concurso:'" :closeModal="close" :getMore="getMoreViewers" />
</template>
